import Script from 'next/script';
import { LotusProduct } from 'types/LDT';
import { ZDTCart } from 'types/ZDT';
import { getEnvConfiguration } from 'utils/env-configuration';
import { getCurrencyCode } from 'utils/money';
import {
  getFirstBreadcrumbsCategoryLabel,
  getPrice,
  getSpecialPrice,
  getSubcategory,
} from 'utils/product';
import { updateDataLayer } from './generateDataLayer/updateDataLayer';

const gtmKey = getEnvConfiguration('GTM_KEY');

export const init = () => {
  let gtmElements = [
    <script key="defaultDataLayer">window.dataLayer = window.dataLayer || [];</script>,
  ];

  if (gtmKey) {
    gtmElements = gtmElements.concat([
      <Script
        key="gtmIntegrate"
        id="googleTagManager"
        strategy="afterInteractive"
      >
        {`
         if(!window.navigator.userAgent.toLowerCase().includes('chrome-lighthouse')) {
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmKey}');}
      `}
      </Script>,
      <noscript
        key="gtmNoScript"
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmKey}" height="0" width="0" style="display: none; visibility: hidden;" />`,
        }}
      />,
    ]);
  }

  return gtmElements;
};

export const getGa4ProductsFromCartItems = (cartItems: ZDTCart.CartItem[] | undefined) => {
  if (!cartItems?.length) {
    return [];
  }

  return cartItems.map((cart) => ({
    ...cart.Product,
    Quantity: cart.Quantity,
  }));
};

export const sendGA4Event = (eventName: string, products: LotusProduct[]) => {
  updateDataLayer({ ecommerce: null }); // Clear the previous ecommerce object.

  let eventParams = null;
  let itemParams = null;

  switch (eventName) {
    case 'view_item':
    case 'add_to_cart':
    case 'add_to_wishlist': {
      const product = products[0];

      const price = getPrice(product);
      const specialPrice = getSpecialPrice(product);

      eventParams = {
        currency: getCurrencyCode(),
        value: specialPrice || price,
      };

      itemParams = [
        {
          item_id: product.ConfigSku,
          item_name: product.Name,
          discount: price - (specialPrice ? specialPrice : price),
          item_brand: product.Brand,
          item_category: getFirstBreadcrumbsCategoryLabel(product),
          item_category2: getSubcategory(product),
          price: specialPrice || price,
          quantity: 1,
        },
      ];

      updateDataLayer({
        event: eventName,
        ecommerce: {
          ...eventParams,
          items: itemParams,
        },
      });

      break;
    }

    case 'view_cart':
    case 'view_item_list':
    case 'begin_checkout': {
      itemParams = products.map((product) => {
        const price = getPrice(product);
        const specialPrice = getSpecialPrice(product);

        return {
          item_id: product.ConfigSku,
          item_name: product.Name,
          discount: price - (specialPrice ? specialPrice : price),
          item_brand: product.Brand,
          item_category: getFirstBreadcrumbsCategoryLabel(product),
          item_category2: getSubcategory(product),
          price: specialPrice || price,
          quantity: (product as LotusProduct & { Quantity: number })?.Quantity || 1, // Quantity is not available LotusProduct type
        };
      });

      updateDataLayer({
        event: eventName,
        ecommerce: {
          items: itemParams,
        },
      });

      break;
    }

    default: {
      break;
    }
  }
};
