import { useDynamicCatalogContext } from 'context/CatalogContext';
import { FC, memo } from 'react';
import Pagination from 'components/Pagination';
import { PER_PAGE } from 'constants/catalog';
import { scrollToElement } from 'utils/catalog/scroll-to-element';

const PAGE_TITLE_ID = 'section__page-title';

const CatalogPagination: FC = () => {
  const {
    data: { products },
  } = useDynamicCatalogContext();

  if (!products) {
    return null;
  }

  const totalPages = products.NumProductFound ? Math.ceil(products.NumProductFound / PER_PAGE) : 0;

  if (totalPages < 2) {
    return null;
  }

  const onPageChange = () => {
    scrollToElement(PAGE_TITLE_ID);
  };

  return (
    <div className="mt-6 flex justify-center">
      <Pagination
        variant="link"
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default memo(CatalogPagination);
