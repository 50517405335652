import { QueryParams } from 'api/APIClient';
import { FILTER_ALL } from 'utils/filters/priceFilter';
import { getCurrencyCode } from 'utils/money';
import { PageInfo } from './catalog-page-type';
import { getCatalogParams } from './query';

export const getPriceFilterLabel = (minPrice: string, maxPrice: string) => {
  const currencyCode = getCurrencyCode();

  if (maxPrice === FILTER_ALL) {
    return `${currencyCode} > ${minPrice}`;
  }

  if (minPrice === FILTER_ALL) {
    return `${currencyCode} < ${maxPrice}`;
  }

  return `${currencyCode} ${minPrice}  - ${currencyCode} ${maxPrice}`;
};

export const getPriceFilterValue = ({
  fieldId,
  params,
}: {
  fieldId: string | undefined;
  params: QueryParams | undefined;
}) => {
  if (!params || !fieldId) {
    return undefined;
  }

  const value = [params[fieldId]];
  const [filterValue] = value.flat(3);

  return filterValue;
};

export const getPriceFilterParams = ({
  fieldId,
  pageInfo,
  routerParams,
  editParams,
}: {
  fieldId: string;
  pageInfo: PageInfo;
  routerParams: QueryParams;
  editParams?: QueryParams;
}) => {
  let params = routerParams;

  if (editParams) {
    const editValue = getPriceFilterValue({ fieldId, params: editParams });

    if (editValue !== undefined) {
      params = {
        ...editParams,
        [fieldId]: editValue,
      };
    }
  }

  return getCatalogParams(params, pageInfo);
};
